import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Box } from "theme-ui";
import Layout from "../components/layout";
import Section, { MostPopular } from "../components/project/Section";
import Link from "../components/Link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {
      /* TODO: Search for projects by their keywords in GitHub */
    }
    <Layout title="Projects" mdxType="Layout">
      <h1>{`Personal Projects`}</h1>
      <h2>{`Most Popular`}</h2>
      <MostPopular numRepos={6} mdxType="MostPopular" />
      <br></br>
      <h2>{`Web/Application Development`}</h2>
      <Section name="app" mdxType="Section" />
      <br></br>
      <h2>{`Artificial Intelligence`}</h2>
      <Section name="ai" mdxType="Section" />
      <br></br>
      <h2>{`Web Scraping`}</h2>
      <Section name="scrape" mdxType="Section" />
      <br></br>
      <h2>{`Filesystem`}</h2>
      <Section name="fs" mdxType="Section" />
      <br></br>
      <h2>{`Miscellaneous Packages`}</h2>
      <Section name="misc" mdxType="Section" />
      <Box sx={{
        textAlign: "center"
      }} mdxType="Box">
        <p><a parentName="p" {...{
            "href": "https://github.com/dawsonbooth/"
          }}>{`Check out all my open source projects on my GitHub!`}</a></p>
      </Box>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      